<template>
  <div
    v-resize="onResize"
    eagle-video-player="root"
    :style="playerRootStyle"
  >
    <component
      v-if="component"
      ref="videoPlayerInstance"
      :is="component"
      :width="width"
      :height="height"
      :video="video"
    ></component>
  </div>
</template>

<script>
export default {
  props: {
    video: {
      type: Object,
      default: () => null,
    },
    fill: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    width: 0,
    height: 0,
    onResizeTimeout: null,
  }),
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.setupPlayerSizeInfo()
    },
    setupPlayerSizeInfo() {
      this.width = $(this.$el).width()
      this.height = $(this.$el).height()
    },
    pause() {
      this.$refs.videoPlayerInstance.pause()
    },
    async onVisible() {
      await this.$nextTick()
      this.onResize()
    },
    onResize() {
      if(!$(this.$el).is(":visible")) return
      this.setupPlayerSizeInfo()
    },
  },
  computed: {
    playerRootStyle() {
      if(this.fill) {
        return {
          width: '100%',
          height: '100%',
        }
      }

      return {
        width: '400px',
        height: '300px',
      }
    },
    videoType() {
      if(!this.video) return null
      return this.video.type
    },
    component() {
      const componentName = `player-${this.videoType}`
      if(this.$options.components[componentName]) {
        return this.$options.components[componentName]
      }
      return null
    },
  },
  components: {
    'player-youtube': () => import('./playerKernel/youtube.vue'),
    'player-static_video': () => import('./playerKernel/videoJs.vue'),
    'player-streaming_vod': () => import('./playerKernel/videoJs.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
